import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from '../../shared/services/loader.service';
export const InterceptorSkipLoader= 'X-Skip-Interceptor';
export const InterceptorSkipLoader_fromWidget= 'Widget-Skip-Interceptor';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public loaderService: LoaderService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (req.headers.has(InterceptorSkipLoader) || req.headers.has(InterceptorSkipLoader_fromWidget))
        {
          if(req.headers.get('Widget-Skip-Interceptor')== 'true' || req.headers.get('Widget-Skip-Interceptor')== '' ||req.headers.get('X-Skip-Interceptor')== '' ){
            return next.handle(req).pipe(
                finalize(() => 
                // this.loaderService.hide()

                
      setTimeout(()=>{
        this.loaderService.hide()
      },4000)
                )
            );
          }else{
            return next.handle(req);
          }
             
        }
        else{
            this.loaderService.show();
            return next.handle(req).pipe(
                finalize(() => 

                // this.loaderService.hide()
                
      setTimeout(()=>{
        this.loaderService.hide()
      },4000)

                )
            );
        }
       
    }
}
