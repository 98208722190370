import { Injectable } from '@angular/core';
// import { CanActivate } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'; //
import { selectIsAuthenticated } from './auth.selectors';
import { AppState } from '../core.state';
import{AuthenticationService} from '../../shared/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router, private authenticationService: AuthenticationService) {}

  // canActivate(): Observable<boolean> {
  //   return this.store.pipe(select(selectIsAuthenticated));
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
        // authorised so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    return false;
 }
}
