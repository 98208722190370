import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../../shared/services/authentication.service';

import { environment } from '../../../environments/environment';

import { NotificationService } from '../notifications/notification.service';
import { DataService } from '../../shared/services/data.service';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationsService: NotificationService, private dataService: DataService,
    private authenticationService:AuthenticationService) {
    super();
  }

  override handleError(error: HttpErrorResponse) {
    let serversideMessage;
    if (error.error instanceof ErrorEvent) {
   
      // client-side error
      //displayMessage = `Error: ${error.error.message}`;
      let displayMessage = 'An error occurred.';

      if (!environment.production) {
        displayMessage += ' See console for details.';
      }
      this.notificationsService.error(displayMessage);
    
      console.log("error", error);
    }
    else {
      
      // server-side error
      serversideMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if ([401].indexOf(error.status) !== -1) {
        
         this.dataService.errorUnauthorized="Invalid Username or Password"
         console.log("error",this.dataService.errorUnauthorized);
         console.log(error);
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api

      }
      else if ([409].indexOf(error.status) !== -1) {
        this.dataService.errorWhenInUse = "You can not delete this data because it is already in use";
        console.log("error",this.dataService.errorWhenInUse);
      }
      else if ([202].indexOf(error.status) !== -1) {
        this.dataService.errorUsedregionBrand = "Incorrect region and brand name, valid region and brand values are <region>-<brand> e.g. emea-bauknecht,emea-hotpoint,emea-whirlpool,nar-jennair,nar-kitchenaid,nar-maytag,nar-whirlpool";
        console.log("error",this.dataService.errorUsedregionBrand);
        console.log(error);
      }
      else if ([500].indexOf(error.status) !== -1) {
        this.dataService.error_500_status = "Something went wrong on server side";
        console.log(error.status + "--" + error.error.error);
      }
      else {
        if (this.notificationsService != undefined) {
          this.notificationsService.servererror(error.status,error.message);
          console.log('Error Code'+error.status+'errMsg'+error.message)
        }

     
      }
    }
    //super.handleError(error);
  }
}
