import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  DatePipe, HashLocationStrategy,
  LocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import { ToastrModule } from 'ng6-toastr-notifications';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    // Ng2SearchPipeModule,
    CoreModule,
    SharedModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    // DeviceDetectorModule.forRoot()
  ],
  declarations: [AppComponent
    ],
  providers: [
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
