import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './core/core.module';

const routes: Routes = [
  {
    path: '',
    children: [
                { path: '', loadChildren: () => import('./userProfile/login/login.module').then(m => m.LoginModule) },
                // { path: 'forgot-password',loadChildren: () =>import('./Userprofile/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
             ]

 },
  {
    path: '',
    loadChildren: () =>
    import('./features/feature/feature.module').then(m => m.FeatureModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
